<template>
  <el-dialog title="更新项目" :model-value="dialogVisible"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-tabs>

      <el-tab-pane label="表格">
        <el-form :model="formData" label-position="right" label-width="auto">
          <el-form-item label="行业">
            <el-select
                v-model="formData.industry_id"
                filterable
                remote
                placeholder="请选择行业"
                :remote-method="searchIndustries"
                @change="changeIndustry"
            >
              <el-option
                  v-for="item in foundIndustries"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input v-model="formData.project_name" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-for="{question} in quizQuestions" v-bind:key="question.id" :label="question.display_name">
            <template v-if='question.ui_type === "CHECKBOX_WITH_DESCRIPTION"'>
              <check-box-with-description :options="question.meta.options"
                                          v-model="formData.description[question.field_name]">

              </check-box-with-description>
            </template>
            <template v-else-if='question.ui_type === "RADIO_WITH_DESCRIPTION"'>
              <radio-with-description :options="question.meta.options"
                                      v-model="formData.description[question.field_name]">
              </radio-with-description>
            </template>
            <template v-else-if='question.ui_type === "CHECKBOX"'>
              <el-checkbox-group v-model="formData.description[question.field_name]">
                <el-checkbox v-for="option in question.meta.options" :key="option" :value="option" :label="option">
                  {{ option }}
                </el-checkbox>
              </el-checkbox-group>
            </template>
            <template v-else-if='question.ui_type === "RADIO"'>
              <el-radio-group v-model="formData.description[question.field_name]">
                <el-radio v-for="option in question.meta.options" :key="option" :value="option" :label="option">
                  {{ option }}
                </el-radio>
              </el-radio-group>
            </template>
            <template v-else-if='question.ui_type === "TEXT"'>
              <el-input v-model="formData.description[question.field_name]"></el-input>
            </template>
            <template v-else-if='question.ui_type === "DATE"'>
              <el-input v-model="formData.description[question.field_name]"></el-input>
            </template>
            <template v-else-if='question.ui_type === "LIST"'>
              <el-form label-position="right" label-width="auto">
                <el-row v-for="(item, itemIndex) in formData.description[question.field_name]" :key="itemIndex">
                  <el-col :span="6">
                    <el-button
                        @click="() => formData.description[question.field_name].splice(itemIndex, 1)"
                        text bg type="danger" size="small"
                    >
                      <el-icon>
                        <Delete/>
                      </el-icon>
                    </el-button>
                  </el-col>
                  <el-col :span="18">
                    <el-form-item v-for="(schema, itemName) in question.meta.items" :key="itemName"
                                  :label="schema.display_name">
                      <el-input v-model="item[itemName]"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="6" :span="18">
                    <el-button
                        style="width: 100%"
                        @click="() => formData.description[question.field_name].push({})"
                        type="primary"
                        text bg>
                      <el-icon>
                        <Plus/>
                      </el-icon>
                    </el-button>
                  </el-col>
                </el-row>
              </el-form>
            </template>
            <div v-else>
              不支持的类型
              {{ formData.description[question.field_name] }}
            </div>
          </el-form-item>

        </el-form>
        <div class="dialog-footer">
          <el-button text bg @click="closeDialog">放弃</el-button>
          <el-button type="danger" @click="updateProject">更新</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="代码">
        <pre>{{ JSON.stringify(formData.description, null, 2) }}</pre>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script setup>
import {reactive, ref, watch, defineProps, defineEmits, onMounted} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';
import CheckBoxWithDescription from '@/components/CheckBoxWithDescription.vue';
import RadioWithDescription from '@/components/RadioWithDescription.vue';

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  editingUserProject: {
    type: Object,
    default: () => ({}),
  },
});

const quizQuestions = ref([]);
const emit = defineEmits(['update:dialogVisible']);
const formData = reactive({
  industry_id: null,
  project_name: '',
  description: {},
});
const closeDialog = () => {
  emit('update:dialogVisible', false);
};
const foundIndustries = ref([]);

const changeIndustry = () => {
  textmuse_api.getIndustryQuizSchema(formData.industry_id).then(response => {
    const questions = response.data.quiz_questions;
    quizQuestions.value = questions.sort((a, b) => a.position - b.position);
    for (const {question} of questions) {
      if (question.ui_type === 'RADIO_WITH_DESCRIPTION') {
        if (props.editingUserProject.description[question.field_name] && props.editingUserProject.description[question.field_name]) {
          formData.description[question.field_name] = props.editingUserProject.description[question.field_name]
          if (!question.meta.options.includes(formData.description[question.field_name].value)) {
            formData.description[question.field_name].value = {
              value: null,
              description: null,
            }
          }
        } else {
          formData.description[question.field_name] = {
            value: null,
            description: null,
          };
        }
      } else if (question.ui_type === 'CHECKBOX_WITH_DESCRIPTION' &&  props.editingUserProject.description[question.field_name]) {
        formData.description[question.field_name] = props.editingUserProject.description[question.field_name].filter(x => {
          return question.meta.options.includes(x.value);
        })
      } else if (question.ui_type === 'LIST') {
        if (props.editingUserProject.description[question.field_name] && props.editingUserProject.description[question.field_name]) {
          formData.description[question.field_name] = props.editingUserProject.description[question.field_name];
        } else {
          formData.description[question.field_name] = [];
        }
      } else {
        formData.description[question.field_name] = props.editingUserProject.description[question.field_name];
      }
    }
  });
};

const searchIndustries = (query) => {
  textmuse_api.searchIndustries(query).then(response => {
    foundIndustries.value = response.data;
  });
};

const updateProject = async () => {
  try {
    await textmuse_api.updateUserProject(props.editingUserProject.uuid, {
      project_name: formData.project_name,
      industry_id: formData.industry_id,
      description: formData.description,
      is_default: formData.is_default,
    });
    ElNotification({
      message: '更新成功',
      type: 'success',
    });
    closeDialog();
  } catch (error) {
    await ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
      confirmButtonText: '确定',
      type: 'error',
    });
  }
};

watch(() => props.dialogVisible, (newValue, oldValue) => {
  if (oldValue === false && newValue === true) {
    formData.project_name = props.editingUserProject.project_name;
    formData.industry_id = props.editingUserProject.industry.id;
    formData.is_default = props.editingUserProject.is_default;
    formData.description = Object();
    changeIndustry();
  } else {
    formData.project_name = '';
    formData.industry_id = null;
    formData.description = Object();
  }
});

onMounted(() => {
  searchIndustries();
});

</script>

<style scoped>
</style>
