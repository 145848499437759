<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑", sans-serif;
  background-color: #f5f5f5;
}

div.json-detail {
  min-width: 60rem;

  pre {
    margin: 0 auto;
    text-wrap: wrap;
    max-height: 24rem;
    overflow-y: scroll;
    min-width: 100%;
  }

}
div:has(.detail-dialog-content) {
  width: 100%;
}
.detail-dialog-content {
  div {
    margin-top: 1.25rem;
  }
}
</style>
