<template>
  <section>
    <el-row style="max-width: 800px">
      <el-col>

        <el-form label-width="auto">
          <el-form-item label="编写提示词">
            <el-select
                filterable
                remote
                v-model="chosenWritePrompt"
                placeholder="请选择提示词模板"
                :remote-method="searchPrompts"
            >
              <el-option
                  v-for="item in foundPrompts"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="检查提示词">
            <el-select
                filterable
                remote
                v-model="chosenReviewPrompt"
                placeholder="请选择提示词模板"
                :remote-method="searchPrompts"
            >
              <el-option
                  v-for="item in foundPrompts"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="改写提示词">
            <el-select
                filterable
                remote
                v-model="chosenRevisePrompt"
                placeholder="请选择提示词模板"
                :remote-method="searchPrompts"
            >
              <el-option
                  v-for="item in foundPrompts"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="用户项目">
            <el-select
                filterable
                remote
                v-model="chosenUserProject"
                placeholder="请选择用户项目"
                :remote-method="searchUserProjects"
            >
              <el-option
                  v-for="item in foundUserProjects"
                  :key="item.uuid"
                  :label="`${item.project_name} (${item.user.display_name})`"
                  :value="item.uuid"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="自定义模板">
            <el-button text @click="showInsertTextTemplateDialog">
              <el-icon>
                <Plus/>
              </el-icon>
              <span>
            从模板库添加
          </span>
            </el-button>
            <el-input
                rows="8"
                type="textarea"
                class="content"
                v-model="userTextTemplate"
            ></el-input>
          </el-form-item>
          <el-form-item label="模板标签">
            <el-input
                rows="1"
                type="textarea"
                class="content"
                v-model="userTextTemplateTags"
            ></el-input>
          </el-form-item>
          <el-form-item label=" ">
            <el-button :disabled="isLoading" type="primary" @click="generate">
          <span v-if="isLoading">
            正在生成 ...
          </span>
              <span v-else>
            普通生成
          </span>
            </el-button>
            <el-button :disabled="isLoading" @click="generateAgentic">
          <span v-if="isLoading">
            正在生成 ...
          </span>
              <span v-else>
            高级生成
          </span>
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-if="isLoading">
      <el-col :span="24">
        <el-progress
            :stroke-width="15"
            striped
            striped-flow
            :percentage="processFinished" status="success" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table v-if="generatedTexts.length > 0" :data="generatedTexts" :v-loading="isLoading">
          <el-table-column prop="text" label="生成文本"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div v-for="name in Object.keys(generatedStructText)" :key="name">
          <h2>{{ name }}</h2>
          <ul>
            <li v-for="text in generatedStructText[name]" :key="text">{{ text['text'] }}</li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <ChooseTextTemplateToInsertDialog
        :dialogVisible="insertTextTemplateDialogVisible"
        @update:dialogVisible="insertTextTemplateDialogVisible = $event"
        :on-confirm="insertTextTemplate">
    </ChooseTextTemplateToInsertDialog>

  </section>


</template>

<script setup>
import {ref} from 'vue';
import * as textmuse_api from '@/services/textmuse.js';
import ChooseTextTemplateToInsertDialog from '@/components/ChooseTextTemplateToInsertDialog.vue';
import {ElMessageBox} from 'element-plus';

const insertTextTemplateDialogVisible = ref(false);
const chosenWritePrompt = ref(null);
const chosenReviewPrompt = ref(null);
const chosenRevisePrompt = ref(null);
const foundPrompts = ref([]);
const userTextTemplate = ref('');
const userTextTemplateTags = ref('');
const chosenUserProject = ref(null);
const foundUserProjects = ref([]);
const isLoading = ref(false);
const generatedTexts = ref([]);
const processStep = ref(0);
const processFinished = ref(0);
const structs = ref([]);
const generatedStructText = ref({});

const searchPrompts = async (query) => {
  const response = await textmuse_api.searchPrompt(query, 0, 10);
  foundPrompts.value = response.data;
};

const searchUserProjects = async (query) => {
  const response = await textmuse_api.searchUserProject(query, 0, 10);
  foundUserProjects.value = response.data;
};

const waitText = async (taskId) => {
  const status = await textmuse_api.getTextgenDebugTaskStatus(taskId);
  const state = status.data.state;
  if (processFinished.value < 101) {
    processFinished.value += processStep.value;
  }
  if (state === 'SUCCESS') {
    await reloadAppConfig();
    const tags = userTextTemplateTags.value.split(',');
    const struct = tags.find(item => Object.keys(structs.value).indexOf(item) > -1);
    if (struct) {
      const names = structs.value[struct]
      for (const i in status.data.content) {
        const name = names[Math.floor(i / 3)]
        if (generatedStructText.value[name] === undefined) {
          generatedStructText.value[name] = [status.data.content[i]];
        } else {
          generatedStructText.value[name].push(status.data.content[i]);
        }
      }
    } else {
      generatedTexts.value = status.data.content;
    }
    isLoading.value = false;
    processFinished.value = 100;
  } else if (state === 'FAILURE') {
    await ElMessageBox.alert('任务失败', '错误', {
      confirmButtonText: '确定',
      type: 'error',
    });
    isLoading.value = false;
  } else {
    isLoading.value = true;
    setTimeout(waitText, 1000, taskId);
  }
};

const generate = async () => {
  isLoading.value = true;
  try {
    const resp = await textmuse_api.debugTextGen(chosenWritePrompt.value,
        chosenUserProject.value,
        userTextTemplate.value,
        userTextTemplateTags.value);
    generatedTexts.value = [];
    processFinished.value = 0;
    processStep.value = 8;
    setTimeout(waitText, 1000, resp.data.id);
  } catch ({name, response}) {
    isLoading.value = false;
    if (response.status === 422) {
      await ElMessageBox.alert('生成失败: 请检查是否有漏填项', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    } else {
      await ElMessageBox.alert('生成失败: 发生了没有预料到的错误', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    }
  }
};

const generateAgentic = async () => {
  isLoading.value = true;
  try {
    const resp = await textmuse_api.debugTextGenAgentic(
        chosenWritePrompt.value,
        chosenReviewPrompt.value,
        chosenRevisePrompt.value,
        chosenUserProject.value,
        userTextTemplate.value,
        userTextTemplateTags.value);
    generatedTexts.value = [];
    processFinished.value = 0;
    processStep.value = 1;
    setTimeout(waitText, 1000, resp.data.id);
  } catch ({name, response}) {
    isLoading.value = false;
    if (response.status === 422) {
      await ElMessageBox.alert('生成失败: 请检查是否有漏填项', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    } else {
      await ElMessageBox.alert('生成失败: 发生了没有预料到的错误', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    }
  }
};

const insertTextTemplate = (textTemplate, textTemplateTags) => {
  if (textTemplate) {
    userTextTemplate.value = textTemplate;
  }
  if (textTemplateTags) {
    userTextTemplateTags.value = textTemplateTags.join(',');
  }
  insertTextTemplateDialogVisible.value = false;
};

const showInsertTextTemplateDialog = () => {
  insertTextTemplateDialogVisible.value = true;
};

const reloadAppConfig = async () => {
  const response = await textmuse_api.getAppConfig()
  structs.value = response.data.template.structs;
};

</script>

<style scoped>
.title span {
  font-weight: bold;
}
</style>
