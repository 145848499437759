<template>
  <div>
    <el-radio-group v-model="selectedValue" @change="updateValue">
      <el-radio v-for="option in options" :key="option" :label="option" :value="option">
        {{ option }}
      </el-radio>
    </el-radio-group>
    <el-input
      v-if="selectedValue !== null"
      v-model="description"
      :placeholder="'请填写' + selectedValue + '的描述'"
      @change="updateValue"
    ></el-input>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ value: null, description: '' }),
  },
  options: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const selectedValue = ref(props.modelValue.value);
const description = ref(props.modelValue.description);

const updateValue = () => {
  emit('update:modelValue', { value: selectedValue.value, description: description.value });
};

watch(() => props.modelValue, (newValue) => {
  selectedValue.value = newValue.value;
  description.value = newValue.description;
});
</script>
