<template>
  <section>

    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
        <el-input placeholder="搜索" v-model="searchQuery"
                  size="large"
                  @keyup.enter="searchUserProjects(true)">
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="userProjects"
                  stripe
                  :row-style="{'cursor': 'pointer'}"
                  @row-click="viewDetail">
          <el-table-column label="项目名称" width="280">
            <template v-slot="{ row }">
              <el-text truncated>
                {{ row.project_name }}
              </el-text>
            </template>
          </el-table-column>
          <el-table-column label="行业" width="120">
            <template v-slot="{ row }">
              <el-text truncated>
                {{ row.industry.name }}
              </el-text>
            </template>
          </el-table-column>
          <el-table-column label="内容简介">
            <template v-slot="{ row }">
              <el-text truncated>
                {{ JSON.stringify(row.description) }}
              </el-text>
            </template>
          </el-table-column>
          <el-table-column prop="user.display_name" label="创建用户" width="80"></el-table-column>
          <el-table-column prop="updated_at" label="最后更新" width="120">
            <template v-slot="{ row }">
              {{ timeAgo(row.updated_at || row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="80">
            <template v-slot="{ row }">
              <div>
                <el-button size="small" plain type="primary" @click.stop="updateUserProject(row)">
                  <el-icon><Edit /></el-icon>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="pagination">
          <el-button text bg :disabled="dataOffset < 10" @click="searchUserProjects(false, -10)">上一页</el-button>
          <el-button text bg :disabled="userProjects.length < 10" @click="searchUserProjects(false, 10)">下一页</el-button>
        </div>
      </el-col>
    </el-row>

    <user-project-editing-dialog v-model:dialogVisible="dialogVisible"
                                 :editingUserProject="editingUserProject"/>

  </section>
</template>

<script setup>
import {h, onMounted, ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox} from 'element-plus';
import UserProjectEditingDialog from '@/components/UserProjectEditingDialog.vue';

const userProjects = ref([]);
const searchQuery = ref('');
const dataOffset = ref(0);
const dialogVisible = ref(false);
const editingUserProject = ref(null);

const searchUserProjects = (isNew, step = 0) => {
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0)
      dataOffset.value = 0;
    else
      dataOffset.value += step;
  }
  textmuse_api.searchUserProject(searchQuery.value, dataOffset.value, 10).then(response => {
    userProjects.value = response.data;
  })

};

const viewDetail = (row) => {
  ElMessageBox.alert(
      () => h('div', null, [
        h('pre', null, JSON.stringify(row.description, null, 2)),
      ]),
      row.project_name,
      {
        center: true,
        callback: () => {
        },
        customClass: 'json-detail',
      });
};

const updateUserProject = (row) => {
  editingUserProject.value = row;
  dialogVisible.value = true;
};

onMounted(() => {
  searchUserProjects(true);
});

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchUserProjects(true);
  }
});

</script>

<style scoped>
</style>
