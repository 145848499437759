<template>
  <section>
    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
      <el-input placeholder="搜索" v-model="searchQuery" class="search-input" size="large"
                @keyup.enter="searchPrompts(true)">
      </el-input>
      </el-col>
      <el-col :span="2">
      <el-button type="primary" @click="createPrompt" size="large">
        创建
      </el-button>
      </el-col>
    </el-row>
    <el-table
        :row-style="{'cursor': 'pointer'}"
        @row-click="viewDetail"
        :data="prompts">
      <el-table-column label="标题" width="140">
        <template v-slot="{ row }">
          <el-text truncated>
            {{ row.title }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column label="提示词">
        <template v-slot="{ row }">
          <el-text truncated>
            {{ JSON.stringify(row.prompt) }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column label="最后编辑人" width="100">
        <template v-slot="{ row }">
          {{ (row.editor && row.editor.username) || row.creator.username }}
        </template>
      </el-table-column>

      <el-table-column prop="updated_at" label="最后更新" width="120">
        <template v-slot="{ row }">
          {{ timeAgo(row.updated_at) || timeAgo(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="140">
        <template v-slot="{ row }">
          <div>
            <el-button size="small" type="primary" text bg @click.stop="updatePrompt(row)">
              <el-icon><Edit /></el-icon>
            </el-button>
            <el-button size="small"  type="danger" text bg @click.stop="deletePrompt(row)">
              <el-icon><Delete /></el-icon>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-button text bg :disabled="dataOffset < 10" @click="searchPrompts(false, -10)">上一页</el-button>
      <el-button text bg :disabled="prompts.length < 10" @click="searchPrompts(false, 10)">下一页</el-button>
    </div>
    <prompt-editing-dialog v-model:dialogVisible="dialogVisible" :forCreation="forCreation"
                           :editingPrompt="editingPrompt"/>
  </section>
</template>

<script setup>
import {h, onMounted, ref, watch} from 'vue';
import PromptEditingDialog from '@/components/PromptEditingDialog.vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';

const prompts = ref([]);
const searchQuery = ref('');
const dataOffset = ref(0);
const dialogVisible = ref(false);
const forCreation = ref(true);
const editingPrompt = ref(null);

const searchPrompts = async (isNew, step = 0) => {
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0)
      dataOffset.value = 0;
    else
      dataOffset.value += step;
  }
  const response = await textmuse_api.searchPrompt(searchQuery.value, dataOffset.value, 10);
  prompts.value = response.data;
};

const createPrompt = () => {
  forCreation.value = true;
  editingPrompt.value = null;
  dialogVisible.value = true;
};

const updatePrompt = (prompt) => {
  forCreation.value = false;
  editingPrompt.value = prompt;
  dialogVisible.value = true;
};

const viewDetail = (row) => {
  ElMessageBox.alert(
      () => h('div', null, [
        h('pre', null, JSON.stringify(row.prompt, null, 2)),
      ]),
      `${row.title} (ID=${row.id})`,
      {
        center: true,
        callback: () => {
        },
        customClass: 'json-detail',
      });
};

const deletePrompt = (prompt) => {
  ElMessageBox.confirm(
      () =>
          h('div', null, [
            h('b', null, '确定要删除这个提示词吗?'),
            h('p', null, prompt.title),
          ]),
      '',
      {
        confirmButtonText: '确定',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
    textmuse_api.deletePrompt(prompt.id).then(() => {
      searchQuery.value = null;
      searchPrompts(true);
      ElNotification({
        title: '成功',
        message: '提示词删除成功',
        type: 'success',
      });

    });
  }).catch(() => {
    // handle cancel
  });
};

onMounted(() => {
  searchPrompts(true);
});

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchPrompts(true);
  }
});
</script>

<style scoped>
</style>
