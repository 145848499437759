<template>
  <el-dialog title="文案模板" :model-value="dialogVisible"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-form label-position="right"
             label-width="auto">
      <el-form-item label="行业">
        <el-checkbox-group
            v-model="chosenIndustries">
          <el-checkbox-button
              v-for="industry in industries"
              :key="industry.name"
              :label="industry.name"
              :value="industry.name">
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="分类">
        <el-radio-group
            v-model="chosenCategory">
          <el-radio-button
              v-for="name in categories"
              :key="name"
              :label="name"
              :value="name">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="模板类型">
        <el-radio-group
            v-model="chosenStruct">
          <el-radio-button
              v-for="name in Object.keys(structs)"
              :key="name"
              :label="name"
              :value="name">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-for="section in structs[chosenStruct]" :label="section" :key="section">
        <el-input
            type="textarea"
            rows="3"
            placeholder="模板内容"
            row="2"
            v-model="sectionContent[section]"
        ></el-input>
      </el-form-item>
      <el-form-item label="卖点">
        <el-checkbox-group
            v-model="chosenFeatures">
          <el-checkbox-button
              v-for="feature in features"
              :key="feature"
              :label="feature"
              :value="feature">
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button text bg @click="closeDialog">放弃</el-button>
        <el-button v-if="forCreation" type="primary" @click="addOrUpdateTextTemplate">创建</el-button>
        <el-button v-else type="danger" @click="addOrUpdateTextTemplate">更新</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import {ref, watch, defineProps, defineEmits} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  forCreation: {
    type: Boolean,
    default: false,
  },
  editingTextTemplate: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['update:dialogVisible']);

const industries = ref([]);
const chosenIndustries = ref([]);
const chosenFeatures = ref([]);
const chosenCategory = ref('');
const chosenStruct = ref('');
const sectionContent = ref({});
const structs = ref({});
const features = ref([]);
const categories = ref([]);

const addOrUpdateTextTemplate = () => {
  const userTags = [].concat(
      [chosenCategory.value, chosenStruct.value],
      chosenIndustries.value,
      chosenFeatures.value,
  )
  const content = JSON.stringify(sectionContent.value);
  if (props.forCreation) {
    textmuse_api.createTextTemplate({content: content, user_tags: userTags}).then(() => {
      emit('update:dialogVisible', false);
      ElNotification({
        title: '成功',
        message: '模板创建成功',
        type: 'success',
      });
    }).catch((error) => {
      ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
        confirmButtonText: '确定',
        type: 'error',
      });
    });
  } else {
    const newTextTemplate = {
      content: content,
      user_tags: userTags,
    };
    textmuse_api.updateTextTemplate(props.editingTextTemplate.id, newTextTemplate).then(() => {
      emit('update:dialogVisible', false);
      ElNotification({
        title: '成功',
        message: '模板更新成功',
        type: 'success',
      });
    }).catch((error) => {
      ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
        confirmButtonText: '确定',
        type: 'error',
      });
    });
  }
};

const closeDialog = () => {
  emit('update:dialogVisible', false);
};


const searchIndustries = async () => {
  const response = await textmuse_api.searchIndustries()
  industries.value = response.data;
};

const reloadAppConfig = async () => {
  const response = await textmuse_api.getAppConfig()
  structs.value = response.data.template.structs;
  features.value = response.data.template.features;
  categories.value = response.data.template.categories;
};

watch(() => props.dialogVisible, async (newValue, oldValue) => {
  if (newValue === false && oldValue === true)
    return;

  await searchIndustries();
  await reloadAppConfig();
  if (!props.forCreation && props.editingTextTemplate) {
    const user_tags = Object.values(props.editingTextTemplate.user_tags);
    chosenIndustries.value = user_tags.filter(item => industries.value.map(x => x.name).indexOf(item) > -1);
    chosenFeatures.value = user_tags.filter(item => features.value.indexOf(item) > -1);
    chosenCategory.value = user_tags.find(item => categories.value.indexOf(item) > -1);
    chosenStruct.value = user_tags.find(item => Object.keys(structs.value).indexOf(item) > -1);
    const parsed = JSON.parse(props.editingTextTemplate.content);
    for (const key of structs.value[chosenStruct.value]) {
      sectionContent.value[key] = parsed[key];
    }
  } else {
    // hello
  }
});
</script>


<style scoped>
.content {
  margin-bottom: 1.5rem;
  height: 15em;
}
</style>
