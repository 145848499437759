<template>
  <section>
    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
      <el-input placeholder="搜索" v-model="searchQuery" class="search-input"
                size="large"
                @keyup.enter="searchUsers(true)"></el-input>
      </el-col>
    </el-row>

    <el-table
        :row-style="{'cursor': 'pointer'}"
        @row-click="viewDetail"
        :data="users">
      <el-table-column label="头像" width="">
        <template v-slot="{ row }">
          <img :src="row.avatar" style="height: 32px; width: 32px"/>
        </template>
      </el-table-column>
      <el-table-column label="用户名" width="480">
        <template v-slot="{ row }">
          <el-text truncated>
            {{ row.display_name }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column label="手机号">
        <template v-slot="{ row }">
          <el-text truncated>
            {{ row.mobile }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" width="120">
        <template v-slot="{ row }">
          {{ timeAgo(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="140">
        <template v-slot="{ row }">
          <div>
            <el-button size="small" text bg @click.stop="viewCredits(row)">支付记录</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-button text v-if="dataOffset >= 10" @click="searchUsers(false, -10)">上一页</el-button>
      <el-button text v-if="users.length >= 10" @click="searchUsers(false, 10)">下一页</el-button>
    </div>
  </section>
</template>

<script setup>
import {h, onMounted, ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox} from 'element-plus';
import router from '@/router';

const users = ref([]);
const searchQuery = ref('');
const dataOffset = ref(0);
const dialogVisible = ref(false);

const searchUsers = async (isNew, step = 0) => {
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0) dataOffset.value = 0;
    else dataOffset.value += step;
  }
  const resp = await textmuse_api.getAllUsers(searchQuery.value, dataOffset.value, 10);
  users.value = resp.data.users;
};

const viewDetail = (row) => {
  ElMessageBox.alert(
      () => h('div', null, [h('pre', null, JSON.stringify(row, null, 2))]),
      `${row.display_name} 用户详情`,
      {
        center: true,
        callback: () => {
        },
        customClass: 'json-detail',
      },
  );
};

const viewCredits = (row) => {
  const route = router.resolve({name: 'UserCreditManagement', params: {sec_uid: row.sec_uid}});
  router.push(route.path);
};


onMounted(() => {
  searchUsers(true);
});

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchUsers(true);
  }
});
</script>


<style scoped>
</style>
