import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import WelcomePage from '@/views/console/WelcomePage.vue';
import ConsoleLayout from '@/layouts/ConsoleLayout.vue';
import store from '@/store';
import TextTemplateManager from '@/views/console/TextTemplateManager.vue';
import PromptManager from '@/views/console/PromptManager.vue';
import UserProjectManager from '@/views/console/UserProjectManager.vue';
import TextGenDebug from '@/views/console/TextGenDebug.vue';
import QuestionManager from '@/views/console/QuestionManager.vue';
import IndustryManager from '@/views/console/IndustryManager.vue';
import UserManagement from '@/views/console/UserManagement.vue';
import UserCreditManagement from '@/views/console/UserCreditManagement.vue';
import PackageManagement from '@/views/console/PackageManagement.vue';
import StructureTextGen from '@/views/console/StructureTextGen.vue';

const routes = [
  {
    path: '/console',
    name: 'Console',
    component: ConsoleLayout,
    children: [
      {
        path: '',
        name: 'HomePage',
        redirect: '/console/welcome',
      },
      {
        path: 'welcome',
        name: 'WelcomePage',
        component: WelcomePage,
        meta: {
          title: '用户手册',
          icon: 'Notebook',
        }
      },
      {
        path: 'users',
        name: 'UserManagement',
        component: UserManagement,
        meta: {
          title: '用户管理',
          icon: 'User',
        }
      },
      {
        path: 'users/:sec_uid/credits',
        name: 'UserCreditManagement',
        component: UserCreditManagement,
        meta: {
          title: '用户积分',
          icon: 'Money',
          hidden: true,
        }
      },
      {
        path: 'users/projects',
        name: 'UserProjectManager',
        component: UserProjectManager,
        meta: {
          title: '用户项目',
          icon: 'School',
        }
      },
      {
        path: 'industries',
        name: 'IndustryManager',
        component: IndustryManager,
        meta: {
          title: '行业管理',
          icon: 'OfficeBuilding',
        }
      },
      {
        path: 'questions',
        name: 'QuestionManager',
        component: QuestionManager,
        meta: {
          title: '调研问题',
          icon: 'QuestionFilled',
        }
      },
      {
        path: 'packages',
        name: 'PackageManagement',
        component: PackageManagement,
        meta: {
          title: '套餐管理',
          icon: 'ShoppingTrolley',
        }
      },
      {
        path: 'prompts',
        name: 'PromptManager',
        component: PromptManager,
        meta: {
          title: '提示词',
          icon: 'Opportunity',
        }
      },
      {
        path: 'text-templates',
        name: 'TextTemplateManager',
        component: TextTemplateManager,
        meta: {
          title: '文案模板',
          icon: 'DocumentCopy',
        }
      },
      {
        path: 'textgen/debug',
        name: 'TextGenDebug',
        component: TextGenDebug,
        meta: {
          title: '文案测试',
          icon: 'MagicStick',
        }
      },
      {
        path: 'textgen/structure',
        name: 'StructureTextGen',
        component: StructureTextGen,
        meta: {
          title: '一键测试',
          icon: 'Plus',
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: Login
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 没有Token就跳转登陆
router.beforeEach((to, from, next) => {
  if (!store.state.accessToken && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});
export default router;
