<template>
  <el-container style="height: 100vh;">
    <el-aside width="200px">
      <div class="site-logo">

        <el-icon>
          <Grid/>
        </el-icon>

      </div>
      <el-menu :default-active="$route.path">
        <template
            v-for="route in routes"
            :key="route.name"
        >
          <el-menu-item
              v-if="!route.meta.hidden"
              :index="$router.resolve({name: route.name}).path"
              @click="$router.push({name: route.name})"
          >
            <el-icon>
              <component :is="route.meta.icon"></component>
            </el-icon>
            <span>{{ route.meta.title }} </span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="header-content">
          <h1 class="page-title">{{ $route.meta.title }}</h1>
          <el-dropdown size="large" trigger="click" @command="handleCommand">
            <div class="el-dropdown-link">
              <div class="user-avatar">
                {{ store.state.user.username[0] }}
              </div>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="showChangePasswordDialog">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {inject} from 'vue';
import {useRouter} from 'vue-router';
import {ElMessageBox, ElNotification} from 'element-plus';
import * as textmuse_api from '@/services/textmuse';

export default {
  name: 'ConsoleLayout',
  setup() {
    const router = useRouter();
    const store = inject('store');
    const routes = router.getRoutes().filter(route => route.name === 'Console')[0].children.filter(
        route => route.meta !== undefined);

    const resolveName = (name) => {
      const {path} = router.resolve({name});
      return path;
    };

    const resolveTitle = (name) => {
      const {meta} = router.resolve({name});
      return meta.title;
    };

    const navigateTo = (viewName) => {
      this.$router.push({name: viewName});
    };

    const logout = () => {
      store.dispatch('logout');
      router.push({name: 'LoginPage'});
    };

    const changeUserPassword = async (newPassword) => {
      try {
        await textmuse_api.changeUserPassword(newPassword);
        ElNotification({
          message: '密码修改成功',
          type: 'success',
        });
      } catch (error) {
        await ElMessageBox.alert('密码修改失败，请重试', '错误', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    };

    const showChangePasswordDialog = () => {
      ElMessageBox.prompt('请输入新密码', '密码修改', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputType: 'password',
        inputValidator: (value) => {
          if (!value) {
            return '密码不能为空';
          }
          if (value.length < 6) {
            return '密码长度不能少于6位';
          }
          return true;
        },
        inputErrorMessage: '密码格式不正确',
      }).then(({value}) => {
        changeUserPassword(value);
      }).catch(() => {
      });
    };

    const handleCommand = (command) => {
      if (command === 'logout') {
        logout();
      } else if (command === 'showChangePasswordDialog') {
        showChangePasswordDialog();
      }
    };

    return {
      store,
      routes,
      navigateTo,
      resolveName,
      resolveTitle,
      handleCommand,
    };
  },
};
</script>

<style lang="scss">
.el-aside {
  text-align: center;
  background-color: #333;

  .site-logo {
    font-size: 35px;
    font-family: "宋体", serif;
    text-align: center;
    display: inline-block;
    padding: 5px 10px;
    margin: .5rem 0;
    cursor: pointer;
    color: #eee;
  }

  .el-menu {
    background-color: #333;
    color: #faf3ee;
    border: 0;

    .el-menu-item {
      color: #faf3ee;
    }

    .el-menu-item:hover {
      background-color: #4078c0;
      font-weight: bold;
      color: #fff;
    }

    .is-active {
      background-color: #f5f5f5;
      color: #333;
      font-weight: bold;
    }

    .is-active:hover {
      background-color: #4078c0;
      font-weight: bold;
      color: #fff;
    }

  }

}

.el-header {
  border-bottom: 1px solid #e8e8e8;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 60px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    background-color: #3498db;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .el-dropdown-link {
    cursor: pointer;
  }
}

.el-main {
  .page-title {
    color: #555;
    padding: 20px 0;
  }

  .main-top {
    margin-bottom: .8rem;
  }

  .pagination {
    text-align: center;
    margin-top: .5rem;
  }

  .el-table {
    border: 1px solid #eee !important;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>

