import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/index.css';
import {formatDistanceToNow} from 'date-fns';
import {zhCN} from 'date-fns/locale';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { textmuseClient } from './services/textmuse';

const app = createApp(App)
.use(store)
.use(router)
.use(ElementPlus, {locale: zhCn})
.mixin({
  methods: {
    timeAgo(date) {
      if (!date) {
        return '';
      }
      return formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: zhCN
      });
    }
  }
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

textmuseClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch('logout').then(() => {
        router.push({ name: 'LoginPage' });
      })
    }
  }
  return Promise.reject(error);
});
app.mount('#app');
