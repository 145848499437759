<template>
  <section>
    <el-dialog title="奖励点数" :model-value="dialogVisible"
               @update:modelValue="val => $emit('update:dialogVisible', val)">

      <el-form label-position="right" label-width="100px">
        <el-form-item label="奖励点数">
          <el-input v-model="bonus"></el-input>
        </el-form-item>
        <el-form-item label="发放原因">
          <el-select
              v-model="reason"
              placeholder="Select"
              size="large"
              style="width: 240px"
          >
            <el-option
                v-for="item in reasonOptions"
                :key="item.value"
                :label="item.value"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="comment"></el-input>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="dialog-footer">

          <el-button text bg @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="bonusUser">奖励</el-button>
        </div>
      </template>

    </el-dialog>
  </section>
</template>
<script setup>
import {defineProps, defineEmits, ref} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

const bonus = ref('');
const comment = ref('');
const reason = ref('用户颜值很高');
const reasonOptions = ref([
  {
    'value': '用户颜值很高',
  },
  {
    'value': '新用户注册',
  },
])
const props = defineProps({
  dialogVisible: Boolean,
  userSecUid: String,
});

const emit = defineEmits(['update:dialogVisible']);

const closeDialog = () => {
  bonus.value = '';
  comment.value = '';
  reason.value = '';
  emit('update:dialogVisible', false);
};

const bonusUser = () => {
  textmuse_api.bonusUserCredit(props.userSecUid, bonus.value, reason.value, comment.value).then(() => {
    ElNotification({
      title: '成功',
      message: '充值成功',
      type: 'success',
    });
    closeDialog();
  }).catch((error) => {
    ElMessageBox.alert(prettifyResponseError(error), '充值失败', {
      confirmButtonText: '确定',
      type: 'error',
    });
  })
};

</script>


