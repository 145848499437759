<template>
  <el-dialog title="提示词" :model-value="dialogVisible"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-input
        class="title"
        placeholder="提示词标题"
        v-model="newTitle"
    ></el-input>
    <el-input
        rows="10"
        type="textarea"
        class="prompt"
        placeholder="提示词(JSON格式)"
        v-model="newPrompt"
    ></el-input>
    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button @click="closeDialog">放弃</el-button>
        <el-button v-if="forCreation" type="primary" @click="addOrUpdatePrompt">创建</el-button>
        <el-button v-else type="danger" @click="addOrUpdatePrompt">更新</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {ref, watch} from 'vue';
import {prettifyResponseError} from '@/services/utils';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    forCreation: {
      type: Boolean,
      default: false,
    },
    editingPrompt: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:dialogVisible'],
  setup(props, {emit}) {
    const newTitle = ref('');
    const newPrompt = ref('');

    const addOrUpdatePrompt = () => {
      let prompt = null;
      try {
        prompt = JSON.parse(newPrompt.value);
      } catch(e){
        ElMessageBox.alert('提示词格式错误, 提示词必须为有效的 JSON 数据', '错误', {
          confirmButtonText: '确定',
          type: 'error',
        });
        return;
      }
      if (props.forCreation) {
        textmuse_api.createPrompt({title: newTitle.value, prompt: prompt}).then(() => {
          emit('update:dialogVisible', false);
          ElNotification({
            title: '成功',
            message: '提示词创建成功',
            type: 'success',
          });
        }).catch((error) => {
          ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        });
      } else {
        textmuse_api.updatePrompt(props.editingPrompt.id, {title: newTitle.value, prompt: prompt}).
            then(() => {
              emit('update:dialogVisible', false);
              ElNotification({
                title: '成功',
                message: '提示词更新成功',
                type: 'success',
              });
            }).
            catch((error) => {
              ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
                confirmButtonText: '确定',
                type: 'error',
              });
            });
      }
    };
    const closeDialog = () => {
      emit('update:dialogVisible', false);
    };

    watch(() => props.dialogVisible, () => {
      if (!props.forCreation && props.editingPrompt) {
        newTitle.value = props.editingPrompt.title;
        newPrompt.value = JSON.stringify(props.editingPrompt.prompt, null, 2);
      } else {
        newTitle.value = null;
        newPrompt.value = null;
      }
    });
    return {
      addOrUpdatePrompt,
      closeDialog,
      newTitle,
      newPrompt,
    };
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 1.5rem;
}
.prompt {
  margin-bottom: 1.5rem;
  height: 15em;
}
</style>
