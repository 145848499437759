<template>
  <section>
    <el-row style="max-width: 800px">
      <el-col>

        <el-form label-width="auto">
          <el-form-item label="用户项目">
            <el-select
                filterable
                remote
                v-model="chosenUserProject"
                placeholder="请选择用户项目"
                :remote-method="searchUserProjects"
            >
              <el-option
                  v-for="item in foundUserProjects"
                  :key="item.uuid"
                  :label="`${item.project_name} (${item.user.display_name})`"
                  :value="item.uuid"
              />
            </el-select>
          </el-form-item>

          <el-form-item label=" ">
            <el-button :disabled="isLoading" type="primary" @click="generate">
          <span v-if="isLoading">
            正在生成 ...
          </span>
              <span v-else>
            普通生成
          </span>
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-if="isLoading">
      <el-col :span="24">
        <el-progress
            :stroke-width="15"
            striped
            striped-flow
            :percentage="processFinished" status="success" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table v-if="generatedTexts.length > 0" :data="generatedTexts" :v-loading="isLoading">
          <el-table-column prop="text" label="生成文本"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row v-if="generatedItems.length > 0">
      <el-col :span="24">
        <h2>生成文案</h2>
        <el-table
            :data="generatedItems">
          <el-table-column label="生成文案">
            <el-table-column  label="钩子开头">
              <template #default="{ row }">
                <el-text>{{ row.data.钩子开头 }}</el-text>
              </template>
            </el-table-column>
            <el-table-column  label="加强情绪">
              <template #default="{ row }">
                <el-text>{{ row.data.加强情绪 }}</el-text>
              </template>
            </el-table-column>
            <el-table-column  label="带出卖点">
              <template #default="{ row }">
                <el-text>{{ row.data.带出卖点 }}</el-text>
              </template>
            </el-table-column>
            <el-table-column  label="结果引导">
              <template #default="{ row }">
                <el-text>{{ row.data.结果引导 }}</el-text>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="采用模板">
              <el-table-column label="钩子开头">
                <template #default="{ row }">
                  <el-text>{{ row.from.钩子开头 }}</el-text>
                </template>
              </el-table-column>
              <el-table-column label="加强情绪">
                <template #default="{ row }">
                  <el-text>{{ row.from.加强情绪 }}</el-text>
                </template>
              </el-table-column>
              <el-table-column label="带出卖点">
                <template #default="{ row }">
                  <el-text>{{ row.from.带出卖点 }}</el-text>
                </template>
              </el-table-column>
              <el-table-column  label="结果引导">
                <template #default="{ row }">
                  <el-text>{{ row.from.结果引导 }}</el-text>
                </template>
              </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </section>


</template>

<script setup>
import {ref} from 'vue';
import * as textmuse_api from '@/services/textmuse.js';
import {ElMessageBox} from 'element-plus';

const chosenUserProject = ref(null);
const foundUserProjects = ref([]);
const isLoading = ref(false);
const generatedTexts = ref([]);
const processStep = ref(0);
const processFinished = ref(0);
const structs = ref([]);
const generatedItems = ref([]);
const correspondingTemplates = ref([]);

const searchUserProjects = async (query) => {
  const response = await textmuse_api.searchUserProject(query, 0, 10);
  foundUserProjects.value = response.data;
};

const waitText = async (taskId) => {
  const status = await textmuse_api.getTextgenDebugTaskStatus(taskId);
  const state = status.data.state;
  if (processFinished.value < 101) {
    processFinished.value += processStep.value;
  }
  if (state === 'SUCCESS') {
    await reloadAppConfig();
    generatedItems.value = status.data.content;
    correspondingTemplates.value = status.data.content.map(x => x.from);
    isLoading.value = false;
    processFinished.value = 100;
  } else if (state === 'FAILURE') {
    await ElMessageBox.alert('任务失败', '错误', {
      confirmButtonText: '确定',
      type: 'error',
    });
    isLoading.value = false;
  } else {
    isLoading.value = true;
    setTimeout(waitText, 1000, taskId);
  }
};

const generate = async () => {
  isLoading.value = true;
  try {
    const resp = await textmuse_api.structureTextGen(chosenUserProject.value);
    generatedTexts.value = [];
    processFinished.value = 0;
    processStep.value = 4;
    setTimeout(waitText, 1000, resp.data.id);
  } catch ({name, response}) {
    isLoading.value = false;
    if (response.status === 422) {
      await ElMessageBox.alert('生成失败: 请检查是否有漏填项', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    } else {
      await ElMessageBox.alert('生成失败: 发生了没有预料到的错误', '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    }
  }
};

const reloadAppConfig = async () => {
  const response = await textmuse_api.getAppConfig()
  structs.value = response.data.template.structs;
};

</script>

<style scoped>
.title span {
  font-weight: bold;
}
</style>
