<template>
  <div>
    <el-checkbox-group v-model="selectedValues" @change="updateValue">
      <el-row v-for="option in options" :key="option" class="checkbox-with-description" :gutter="20">
        <el-col :span="8">
          <el-checkbox :label="option" :value="option">{{ option }}</el-checkbox>
        </el-col>
        <el-col :span="16">
          <el-input
              v-if="selectedValues.indexOf(option) > -1"
              v-model="descriptions[option]"
              placeholder="描述"
              @change="updateValue"></el-input>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const selectedValues = ref({});
const descriptions = ref({});
const updateValue = () => {
  const updatedValue = selectedValues.value.map(value => ({
    value,
    description: descriptions.value[value] || '',
  }));
  emit('update:modelValue', updatedValue);
};

const initValue = (newValue) => {
  selectedValues.value = newValue.map(item => item.value);
  descriptions.value = Object();
  Object.assign(descriptions.value, newValue.reduce((obj, item) => {
    obj[item.value] = item.description;
    return obj;
  }, {}));
}

watch(() => props.modelValue, (newValue) => {
  initValue(newValue);
});

initValue(props.modelValue);

</script>

<style scoped>
.checkbox-with-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-with-description .el-input {
  margin-left: 10px;
  flex-grow: 1;
}
</style>
