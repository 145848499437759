import { createStore } from 'vuex';
import { jwtDecode } from "jwt-decode";

export default createStore({
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    user: (() => {try {
      return jwtDecode(localStorage.getItem('accessToken'))
    } catch (e) {
      return null
    }})()
  },
  mutations: {
    loginUser(state, loginData) {
      state.accessToken = loginData.access_token;
      state.user = jwtDecode(state.accessToken)
      localStorage.setItem('accessToken', loginData.access_token);
    },
    logoutUser(state) {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    }
  },
  getters: {
    getAccessToken: state => state.accessToken,
  },
  actions: {
    login({ commit }, loginData) {
      commit('loginUser', loginData);
    },
    logout({ commit }) {
      commit('logoutUser');
    }
  },
  modules: {}
});
