import {h} from 'vue';

export const prettifyResponseError = (error) => {
  if (error.response.status === 422) {
    try {
      //let message = '';
      let nodes = [];
      for (const item of error.response.data.detail) {
        // message += `${item.loc[item.loc.length - 1]}: ${item.msg}\n`;
        nodes.push(h('p', null, [
          h('b', null, item.loc[item.loc.length - 1] + ': '),
          h('span', null, item.msg),
        ]));
      }
      console.log(nodes);
      return h('div', null, nodes);
    } catch {
      return '服务器内部错误';
    }
  } else if (Math.floor(error.response.status / 100) === 4) {
    return error.response.data.detail;
  } else {
    return error.message;
  }
};

export const sha256 = async (str) => {
  // 使用SHA-256对密码进行哈希处理（可选）
  const hashed = await crypto.subtle.digest('SHA-256',
      new TextEncoder().encode(str));
  return Array.from(new Uint8Array(hashed)).
      map(b => b.toString(16).padStart(2, '0')).
      join('');
};

export const encryptPassword = async (password) => {
  return await sha256(password);
};
