<template>
  <el-dialog title="编辑行业" :model-value="dialogVisible" class="industry-editing-dialog"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-text type="danger" v-if="dialogForCreation">行业标签设置后将不可再修改，请谨慎设置</el-text>
    <el-form
        label-position="right"
        label-width="auto"
        :model="formData">
      <el-form-item label="行业名称">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="行业标签">
        <el-input v-model="formData.tag_name" autocomplete="off" :disabled="!dialogForCreation"></el-input>
      </el-form-item>
      <el-form-item label="额外标签">
        <el-input v-model="formData.user_tags" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="closeDialog">放弃</el-button>
      <el-button v-if="dialogForCreation" type="primary" @click="submitForm">创建</el-button>
      <el-button v-else type="danger" @click="submitForm">更新</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

export default {
  props: {
    dialogVisible: Boolean,
    dialogForCreation: Boolean,
    editingIndustry: Object,
  },
  emits: ['update:dialogVisible'],
  setup(props, {emit}) {
    const formData = ref(props.editingIndustry || {name: '', tag_name: '', user_tags: []});

    const closeDialog = () => {
      emit('update:dialogVisible', false);
    };

    const submitForm = () => {
      if (props.dialogForCreation) {
        textmuse_api.createIndustry({
          name: formData.value.name,
          tag_name: formData.value.tag_name,
          user_tags: formData.value.user_tags.split(/[,，]/),
        }).then(() => {
          closeDialog();
          ElNotification({
            title: '成功',
            message: '行业创建成功',
            type: 'success',
          });
        }).catch(error => {
          ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        });
      } else {
        textmuse_api.updateIndustry(props.editingIndustry.id, {
          name: formData.value.name,
          user_tags: formData.value.user_tags.split(/[,，]/),
        }).then(() => {
          closeDialog();
          ElNotification({
            title: '成功',
            message: '行业更新成功',
            type: 'success',
          });
        }).catch(error => {
          ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        });
      }
    };

    watch(() => props.dialogVisible, () => {
      if (!props.dialogForCreation && props.editingIndustry) {
        formData.value.name = props.editingIndustry.name;
        formData.value.tag_name = props.editingIndustry.tag_name;
        formData.value.user_tags = props.editingIndustry.user_tags.join(',');
      } else {
        formData.value.name = null;
        formData.value.tag_name = null;
        formData.value.user_tags = '';
      }
    });

    return {formData, closeDialog, submitForm};
  },
};

</script>

<style lang="scss" scoped>
.industry-editing-dialog {
  .dialog-footer {
    text-align: right;
  }

  label {
    min-width: 4.5rem;
    text-align: right;
  }
}

</style>
