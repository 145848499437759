<template>
  <section>
    <h2>用户手册</h2>
    <div>
      <p>
        自己试试看吧～
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WelcomePage'
}
</script>
<style scoped>
h2 {
  color: #555;
}
</style>
