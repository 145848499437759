<template>
  <el-dialog title="套餐" :model-value="dialogVisible" class="package-editing-dialog"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-form
        label-position="right"
        label-width="auto"
        :model="formData">
      <el-form-item label="标题">
        <el-input v-model="formData.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="formData.description" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="点数">
        <el-input v-model="formData.paid_credits" type="number" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="奖励点数">
        <el-input v-model="formData.bonus_credits" type="number" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input v-model="formData.price" type="number" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-switch v-model="formData.display"></el-switch>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="formData.comment" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="closeDialog">放弃</el-button>
      <el-button v-if="dialogForCreation" type="primary" @click="submitForm">创建</el-button>
      <el-button v-else type="danger" @click="submitForm">更新</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

export default {
  props: {
    dialogVisible: Boolean,
    dialogForCreation: Boolean,
    editingPackage: Object,
  },
  emits: ['update:dialogVisible'],
  setup(props, {emit}) {
    const formData = ref(props.editingPackage || {
      title: '',
      description: '',
      paid_credits: 0,
      bonus_credits: 0,
      price: 0,
      display: false,
      comment: '',
    });

    const closeDialog = () => {
      emit('update:dialogVisible', false);
    };

    const submitForm = () => {
      if (props.dialogForCreation) {
        const submitValue = {
          ...formData.value,
          price: Math.round(formData.value.price * 100),
        }
        textmuse_api.createPackage(submitValue).then(() => {
          closeDialog();
          ElNotification({
            title: '成功',
            message: '套餐创建成功',
            type: 'success',
          });
        }).catch(error => {
          ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        });
      } else {
        const submitValue = {
          ...formData.value,
          price: Math.round(formData.value.price * 100),
        }
        textmuse_api.updatePackage(props.editingPackage.id, submitValue).then(() => {
          closeDialog();
          ElNotification({
            title: '成功',
            message: '套餐更新成功',
            type: 'success',
          });
        }).catch(error => {
          ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        });
      }
    };

    watch(() => props.dialogVisible, () => {
      if (!props.dialogForCreation && props.editingPackage) {
        formData.value = {
          ...props.editingPackage,
          price: (props.editingPackage.price / 100).toFixed(2)
        };
      } else {
        formData.value = {
          title: '',
          description: '',
          paid_credits: 0,
          bonus_credits: 0,
          price: 0,
          display: true,
          comment: '',
        };
      }
    });

    return {formData, closeDialog, submitForm};
  },
};
</script>

<style lang="scss" scoped>
.package-editing-dialog {
  .dialog-footer {
    text-align: right;
  }

  label {
    min-width: 4.5rem;
    text-align: right;
  }
}
</style>
