<template>
  <section>
    <el-row align="middle" justify="end" :gutter="20" class="main-top">
      <el-col :span="2">
        <el-button size="large" type="primary" @click="createIndustry">创建</el-button>
      </el-col>
    </el-row>
    <el-table
        :row-style="{'cursor': 'pointer'}"
        @row-click="viewDetail"
        :data="industries">
      <el-table-column prop="name" label="行业名称"></el-table-column>
      <el-table-column prop="tag_name" label="行业标签" width="120"></el-table-column>
      <el-table-column prop="user_tags" label="额外标签" width="280"></el-table-column>
      <el-table-column prop="updated_at" label="最后更新" width="140">
        <template v-slot="{ row }">
          {{ timeAgo(row.updated_at) || timeAgo(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="{ row }">
          <el-button size="small" text bg @click.stop="editQuiz(row)">
            问卷
          </el-button>
          <el-button size="small" type="primary" text bg @click.stop="updateIndustry(row)">
            <el-icon><Edit /></el-icon>
          </el-button>
          <el-button size="small" type="danger" text bg @click.stop="deleteIndustry(row)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-button text bg :disabled="dataOffset < 10" @click="searchIndustries(false, -10)">上一页</el-button>
      <el-button text bg :disabled="industries.length < 10" @click="searchIndustries(false, 10)">下一页</el-button>
    </div>
    <industry-editing-dialog v-model:dialogVisible="dialogVisible"
                             :dialogForCreation="dialogForCreation"
                             :editingIndustry="editingIndustry"/>
    <industry-quiz-editing-dialog
        v-model:dialog-visible="quizDialogVisible"
        :edit-industry-id="editIndustryId"
    />
  </section>
</template>

<script setup>
import {ref, onMounted, h, watch} from 'vue';
import IndustryEditingDialog from '@/components/IndustryEditingDialog.vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import IndustryQuizEditingDialog from '@/components/IndustryQuizEditingDialog.vue';
import {prettifyResponseError} from '@/services/utils';

const industries = ref([]);
const dialogForCreation = ref(false);
const dialogVisible = ref(false);
const quizDialogVisible = ref(false);
const editIndustryId = ref(null);
const editingIndustry = ref(null);
const searchQuery = ref('');
const dataOffset = ref(0);

const searchIndustries = async (isNew, step = 0) => {
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0)
      dataOffset.value = 0;
    else
      dataOffset.value += step;
  }
  const response = await textmuse_api.searchIndustries(searchQuery.value, dataOffset.value, 10);
  industries.value = response.data;

};

const viewDetail = async (row) => {
  const resp = await textmuse_api.getIndustryById(row.id);
  const industry = resp.data;
  await ElMessageBox.alert(
      () => h('div', null, [
        h('div', null, [
          h('b', null, '行业名称：'),
          h('span', null, industry.name),
        ]),
        h('div', null, [
          h('b', null, '标签：'),
          h('span', null, industry.tag_name),
        ]),
      ]),
      '行业详情',
      {
        callback: () => {}
      }
  );
};

const createIndustry = (industry = null) => {
  editingIndustry.value = industry;
  dialogVisible.value = true;
  dialogForCreation.value = true;
};

const updateIndustry = (industry = null) => {
  editingIndustry.value = industry;
  dialogVisible.value = true;
  dialogForCreation.value = false;
};

const deleteIndustry = async (row) => {
  try {
    await ElMessageBox.confirm(`确定要删除 "${row.name} " 这个行业吗?`, '警告', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    });
    await textmuse_api.deleteIndustry(row.id);
    ElNotification({
      title: '成功',
      message: '行业删除成功',
      type: 'success',
    });
    await searchIndustries(true);
  } catch (error) {
    if (error !== 'cancel') {
      ElNotification({
        title: '失败',
        message: prettifyResponseError(error),
        type: 'info',
      });
    }
  }
};

const editQuiz = (row) => {
  quizDialogVisible.value = true;
  editIndustryId.value = row.id;
};

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchIndustries(true);
  }
});

onMounted(() => {
  searchIndustries(true);
});
</script>

<style scoped>
.page-toolbar {
  margin-bottom: 20px;
}
</style>
