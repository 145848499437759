<template>
  <section>
    <el-row align="middle" justify="start" :gutter="20" class="main-top">
      <el-col :span="4">
        <el-radio-group v-model="searchType" size="large" @change="fetchPackages(true)">
          <el-radio-button :value="true">
            显示
          </el-radio-button>
          <el-radio-button :value="false">
            隐藏
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="搜索" v-model="searchQuery" class="search-input" size="large"
                  @keyup.enter="fetchPackages(true)">
        </el-input>
      </el-col>
      <el-col :offset="12" :span="2">
        <el-button size="large" type="primary" @click="createPackage">创建</el-button>
      </el-col>
    </el-row>
    <el-table :data="packages">
      <el-table-column prop="id" label="ID" width="40"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="paid_credits" label="支付"></el-table-column>
      <el-table-column prop="bonus_credits" label="奖励"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="display" label="显示">
        <template #default="{ row }">
          <el-switch v-model="row.display" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="{ row }">
          <el-button size="small" type="primary" text bg @click.stop="updatePackage(row)">
            <el-icon><Edit /></el-icon>
          </el-button>
          <el-button size="small" type="danger" text bg @click.stop="deletePackage(row)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <package-editing-dialog v-model:dialogVisible="dialogVisible"
                            :dialogForCreation="dialogForCreation"
                            :editingPackage="editingPackage"/>
  </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import PackageEditingDialog from '@/components/PackageEditingDialog.vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

const packages = ref([]);
const dialogForCreation = ref(false);
const dialogVisible = ref(false);
const editingPackage = ref(null);
const searchQuery = ref(null);
const searchType = ref(true);

const fetchPackages = async (isNew, step=0) => {
  console.log(isNew, step)
  const response = await textmuse_api.getPackages(searchQuery.value, searchType.value);
  packages.value = response.data.packages;
};

const createPackage = () => {
  editingPackage.value = null;
  dialogVisible.value = true;
  dialogForCreation.value = true;
};

const updatePackage = (pkg) => {
  editingPackage.value = pkg;
  dialogVisible.value = true;
  dialogForCreation.value = false;
};

const deletePackage = async (pkg) => {
  try {
    await ElMessageBox.confirm(`确定要删除 "${pkg.title}" 这个套餐吗?`, '警告', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    });
    await textmuse_api.deletePackage(pkg.id);
    ElNotification({
      title: '成功',
      message: '套餐删除成功',
      type: 'success',
    });
    await fetchPackages();
  } catch (error) {
    if (error !== 'cancel') {
      ElNotification({
        title: '失败',
        message: prettifyResponseError(error),
        type: 'info',
      });
    }
  }
};

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    fetchPackages();
  }
});

onMounted(() => {
  fetchPackages();
});
</script>

<style scoped>
</style>
