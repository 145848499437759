<template>
  <div>
    <el-dialog title="从文本模板添加" :model-value="dialogVisible"
               @update:modelValue="val => $emit('update:dialogVisible', val)">
      <el-form>
        <el-form-item>
          <el-autocomplete
              v-model="chosenTextTemplate"
              value-key="content"
              :items="textTemplates"
              :fetch-suggestions="searchTextTemplates"
              @select="handleSelect"
              placeholder="搜索文本模板"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
      <div class="dialog-footer">

          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="confirm">添加</el-button>
      </div>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse.js';

export default {
  props: {
    dialogVisible: Boolean,
    onConfirm: Function,
  },
  emits: ['update:dialogVisible'],
  setup(props, {emit}) {
    const textTemplates = ref([]);
    const chosenTextTemplate = ref('');
    const chosenTextTemplateTag = ref('');
    emit('update:dialogVisible', false);

    const searchTextTemplates = async (query, cb) => {
      const response = await textmuse_api.searchTextTemplate(query, 0, 10);
      const templates = Array.from(response.data).map(item => {
        return item;
      });
      cb(templates);
    };

    const handleSelect = (template) => {
      chosenTextTemplate.value = template.content;
      chosenTextTemplateTag.value = template.user_tags.concat(template.system_tags);
    }

    const confirm = () => {
      props.onConfirm(chosenTextTemplate.value, chosenTextTemplateTag.value);
    };

    const closeDialog = () => {
      emit('update:dialogVisible', false);
    };

    watch(() => props.dialogVisible, (newVal) => {
      if (newVal) {
        chosenTextTemplate.value = '';
      }
    });

    return {
      textTemplates,
      chosenTextTemplate,
      chosenTextTemplateTag,
      handleSelect,
      searchTextTemplates,
      confirm,
      closeDialog,
    };
  },
};
</script>

<style scoped>
</style>
