<template>
  <div class="login-page">
    <el-row type="flex" class="row-bg" justify="center" align="middle">
      <el-col :span="8">
        <el-input size="large" v-model="username" placeholder="用户名"></el-input>
        <el-input size="large" v-model="password" type="password" placeholder="密码" @keyup.enter="login"></el-input>
        <el-alert
            v-if="errorMessage"
            type="error"
            :title="errorMessage"
            :closable="false"
        ></el-alert>
        <el-button size="large" type="primary" @click="login">登录</el-button>
      </el-col>
      <el-col>
      </el-col>
    </el-row>
    <div class="miit">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2024076101号-1</a>
    </div>
  </div>
</template>

<script>
import {ref, inject} from 'vue';
import {loginWithUsernamePassword} from '@/services/textmuse.js';
import {useRouter} from 'vue-router';

export default {
  name: 'LoginPage',
  setup() {
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const store = inject('store');
    const router = useRouter();

    const login = async () => {
      try {
        const response = await loginWithUsernamePassword(username.value, password.value);
        store.dispatch('login', response.data); // 使用action保存access_token
        errorMessage.value = '';
        console.info('登陆成功:', response);
        await router.push({name: 'WelcomePage'}); // 登录成功后跳转到 WelcomePage
      } catch (error) {
        if (error.response.status === 401) {
          errorMessage.value = '用户名或密码错误';
        } else {
          console.error('登陆服务器故障:', error.response.data);
        }
      }
    };

    return {
      username,
      password,
      login,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  height: 100vh;
  max-width: 960px;
  margin: 0 auto;
}

.row-bg {
  height: 100%;
}

.el-input {
  margin-bottom: 1.5rem;
}

.el-alert {
  margin-bottom: 1.5rem;
}

.el-button {
  width: 100%;
}

.miit {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  text-align: center;
  padding: .5rem .5rem;
  font-size: 0.7rem;
  a {
    color: grey;
  }
}

</style>
