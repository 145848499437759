<template>
  <section>
    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
        <el-input placeholder="搜索" v-model="searchQuery" class="search-input"
                  size="large"
                  @keyup.enter="searchQuestions(true)">
        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="large" @click="toggleDialog(true)">创建</el-button>
      </el-col>
    </el-row>
    <el-table
        :row-style="{'cursor': 'pointer'}"
        @row-click="viewDetail"
        :data="questions">
      <el-table-column prop="display_name" label="显示名称"></el-table-column>
      <el-table-column prop="field_name" label="字段名称"></el-table-column>
      <el-table-column prop="ui_type" label="控件类型">
        <template #default="{ row }">
          <el-select :disabled="true" size="small" v-model="row.ui_type" placeholder="未知">
            <el-option label="单选" value="RADIO"></el-option>
            <el-option label="单选带描述" value="RADIO_WITH_DESCRIPTION"></el-option>
            <el-option label="多选" value="CHECKBOX"></el-option>
            <el-option label="多选带描述" value="CHECKBOX_WITH_DESCRIPTION"></el-option>
            <el-option label="文本" value="TEXT"></el-option>
            <el-option label="日期" value="DATE"></el-option>
            <el-option label="列表" value="LIST"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="value_type" label="存储类型">
        <template #default="{ row }">
          <el-select :disabled="true" size="small" v-model="row.value_type" placeholder="未知">
            <el-option label="整数" value="INTEGER"></el-option>
            <el-option label="实数" value="REAL"></el-option>
            <el-option label="文本" value="TEXT"></el-option>
            <el-option label="日期" value="DATE"></el-option>
            <el-option label="列表" value="LIST"></el-option>
          </el-select>
        </template>

      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120">
        <template #default="{ row }">
          <el-button size="small" text bg type="primary" @click.stop="updateQuestion(row)">
            <el-icon><Edit /></el-icon>
          </el-button>
          <el-button size="small" text bg type="danger" @click.stop="deleteQuestion(row)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-button text bg :disabled="dataOffset < 10" @click="searchQuestions(false, -10)">上一页</el-button>
      <el-button text bg :disalbed="questions.length < 10" @click="searchQuestions(false, 10)">下一页</el-button>
    </div>
    <question-editing-dialog
        v-model:dialogVisible="dialogVisible"
        :dialogForCreation="dialogForCreation"
        :editingQuestion="editingQuestion"
    />
  </section>
</template>

<script setup>
import {h, onMounted, ref, watch} from 'vue';
import QuestionEditingDialog from '@/components/QuestionEditingDialog.vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';

const dialogVisible = ref(false);
const dialogForCreation = ref(false);
const questions = ref([]);
const searchQuery = ref('');
const dataOffset = ref(0);
const editingQuestion = ref(null);

const toggleDialog = (forCreation) => {
  console.log(forCreation);
  dialogForCreation.value = forCreation;
  dialogVisible.value = !dialogVisible.value;
};

const viewDetail = async (row) => {
  const resp = await textmuse_api.getQuestion(row.id);
  const question = resp.data;
  await ElMessageBox.alert(
      () => h('div', {'class': 'detail-dialog-content'}, [
        h('div', {'class': 'description'}, [
          h('b', null, '描述：'),
          h('i', null, question.description),
        ]),
        h('div', null, [
          h('b', null, '字段名称：'),
          h('span', null, question.field_name),
        ]),
        h('div', null, [
          h('b', null, '控件类型：'),
          h('span', null, question.ui_type),
        ]),
        h('div', null, [
          h('b', null, '存储类型：'),
          h('span', null, question.value_type),
        ]),
        h('div', null, [
          h('b', null, '元数据：'),
          h('pre', {style: 'text-wrap: wrap'}, JSON.stringify(question.meta)),
        ]),
      ]),
      `问题: ${question.display_name} (ID=${question.id})`,
      {
        center: true,
        callback: () => {
        },
      });
};

const updateQuestion = async (row) => {
  dialogForCreation.value = false;
  editingQuestion.value = row;
  dialogVisible.value = true;
  dialogVisible.value = true;

};

const deleteQuestion = async (row) => {
  try {
    await ElMessageBox.confirm(
        () =>
            h('div', null, [
              h('b', null, '确定要删除这个问题吗?'),
              h('p', null, row.display_name),
            ]),
        '',
        {
          confirmButtonText: '确定',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: '取消',
          type: 'warning',
        });
    await textmuse_api.deleteQuestion(row.id);
    await searchQuestions(true);
    ElNotification({
      message: '该数据已被成功删除',
      type: 'success',
    });
  } catch (error) {
    if (error !== 'cancel') {
      await ElMessageBox.alert('删除失败: ' + error.message, '错误', {
        confirmButtonText: '确定',
        type: 'error',
      });
    }
  }
};
const searchQuestions = async (isNew, step = 0) => {
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0)
      dataOffset.value = 0;
    else
      dataOffset.value += step;
  }
  const response = await textmuse_api.searchQuestion(searchQuery.value, false, dataOffset.value, 10);
  questions.value = response.data;
};

onMounted(searchQuestions);
watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchQuestions(true);
  }
});

</script>


<style lang="scss" scoped>
.question-detail {
  .description {
    font-size: 14px;
  }
}
</style>
