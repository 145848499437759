<template>
  <section>
    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
        <el-input placeholder="搜索" v-model="searchQuery" class="search-input" size="large"
                  @keyup.enter="searchTextTemplates(true)"></el-input>
      </el-col>
<el-col :span="4">
  <el-upload
      action="/internal/v1/text-templates/upload"
      :headers="{'X-Token': store.getters.getAccessToken}"
      :on-error="onUploadError"
      :on-success="searchTextTemplates"
      :show-file-list="false"
      v-model:file-list="files"
  >
    <el-button size="large">上传 Excel</el-button>
  </el-upload>

</el-col>
      <el-col :span="2">
        <el-button type="primary" size="large" @click="createTextTemplate">
          创建
        </el-button>
      </el-col>
    </el-row>
    <el-table
        :row-style="{'cursor': 'pointer'}"
        @row-click="viewDetail"
        :data="textTemplates">
      <el-table-column label="内容">
        <template v-slot="{ row }">
          <el-text truncated>{{ row.content }}</el-text>
        </template>
      </el-table-column>
      <el-table-column label="标签" width="100">
        <template v-slot="{ row }">
          <el-text truncated>{{ row.user_tags.join(',') }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="updated_at" label="最后编辑人" width="100">
        <template v-slot="{ row }">
          {{ (row.editor && row.editor.username) || row.creator.username }}
        </template>
      </el-table-column>

      <el-table-column prop="updated_at" label="最后更新" width="100">
        <template v-slot="{ row }">
          {{ timeAgo(row.updated_at) || timeAgo(row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="140">
        <template v-slot="{row}">
          <div>
            <el-button size="small" text bg type="primary" @click.stop="updateTextTemplate(row)">
              <el-icon><Edit /></el-icon>
            </el-button>
            <el-button size="small" text bg type="danger" @click.stop="deleteTextTemplate(row)">
              <el-icon><Delete /></el-icon>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-button text bg :disabled="dataOffset < 10" @click="searchTextTemplates(false, -10)">
        上一页
      </el-button>
      <el-button text bg :disabled="textTemplates.length < 10" @click="searchTextTemplates(false, 10)">
        下一页
      </el-button>
    </div>
    <text-template-editing-dialog v-model:dialogVisible="dialogVisible" :forCreation="forCreation"
                                  :editingTextTemplate="editingTextTemplate"/>
  </section>
</template>

<script setup>
import {h, onMounted, ref, watch} from 'vue';
import TextTemplateEditingDialog from '@/components/TextTemplateEditingDialog.vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox} from 'element-plus';
import store from '@/store';

const textTemplates = ref([]);
const editingTextTemplate = ref(null);
const searchQuery = ref('');
const dialogVisible = ref(false);
const dataOffset = ref(0);
const forCreation = ref(false);
const files = ref([]);

const searchTextTemplates = async (isNew, step = 0) => { // add this method
  if (isNew) {
    dataOffset.value = 0;
  } else {
    if (dataOffset.value + step < 0)
      dataOffset.value = 0;
    else
      dataOffset.value += step;
  }
  const response = await textmuse_api.searchTextTemplate(searchQuery.value, dataOffset.value, 10);
  textTemplates.value = response.data;
};

const createTextTemplate = () => {
  dialogVisible.value = true;
  forCreation.value = true;
};

const updateTextTemplate = (textTemplate) => {
  forCreation.value = false;
  editingTextTemplate.value = textTemplate;
  dialogVisible.value = true;
};
const viewDetail = (row) => {
  ElMessageBox.alert(
      () => h('div', {class: 'detail-dialog-content'}, [
        h('div', null, [
          h('p', null, row.content),
        ]),
        h('div', null, [
          h('h3', null, ['人工标签']),
          h('p', null, row.user_tags.join(',')),
        ]),
        h('div', null, [
          h('h3', null, ['机器标签']),
          h('p', null, row.system_tags.join(',')),
        ]),
      ]),
      `ID: ${row.id}`,
      {
        center: true,
        callback: () => {
        },
      });
};
const deleteTextTemplate = (row) => {
  ElMessageBox.confirm(
      () =>
          h('div', null, [
            h('b', null, '确定要删除这个文本模板吗?'),
            h('p', null, row.content),
          ]),
      '',
      {
        confirmButtonText: '确定',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
    textmuse_api.deleteTextTemplate(row.id).then(() => {
      searchQuery.value = null;
      searchTextTemplates(true);
    });
  }).catch(() => {
    // handle cancel
  });
};
const onUploadError = async (e) => {
  await ElMessageBox.alert('上传失败: ' + e.message, '错误', {
    confirmButtonText: '确定',
    type: 'error',
  });
}
onMounted(() => {
  searchTextTemplates(true);
});

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    searchQuery.value = null;
    searchTextTemplates(true);
  }
});
</script>
