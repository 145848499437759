<template>
  <el-dialog class="question-editing-dialog" title="编辑调研问题" :model-value="dialogVisible"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-text type="danger"> "控件类型、存储类型、字段名称在创建后将不可修改" </el-text>
    <el-form
        label-position="right"
        label-width="auto"
        :model="formData">
      <el-form-item label="是否启用">
        <el-checkbox v-model="formData.enabled"></el-checkbox>
      </el-form-item>
      <el-form-item label="显示名称">
        <el-input v-model="formData.displayName"></el-input>
      </el-form-item>
      <el-form-item label="字段名称">
        <el-input v-model="formData.fieldName" :disabled="!dialogForCreation"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" v-model="formData.description"></el-input>
      </el-form-item>
      <el-form-item label="占位符">
        <el-input type="textarea" v-model="formData.placeholder"></el-input>
      </el-form-item>
      <el-form-item label="控件类型">
        <el-select v-model="formData.uiType" placeholder="请选择" :disabled="!dialogForCreation">
          <el-option label="单选" value="RADIO"></el-option>
          <el-option label="单选带描述" value="RADIO_WITH_DESCRIPTION"></el-option>
          <el-option label="多选" value="CHECKBOX"></el-option>
          <el-option label="多选带描述" value="CHECKBOX_WITH_DESCRIPTION"></el-option>
          <el-option label="文本" value="TEXT"></el-option>
          <el-option label="日期" value="DATE"></el-option>
          <el-option label="列表" value="LIST"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="存储类型">
        <el-select v-model="formData.valueType" placeholder="请选择" :disabled="!dialogForCreation">
          <el-option label="整数" value="INTEGER"></el-option>
          <el-option label="实数" value="REAL"></el-option>
          <el-option label="文本" value="TEXT"></el-option>
          <el-option label="日期" value="DATE"></el-option>
          <el-option label="列表" value="LIST"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="备选项"
          v-if="['RADIO', 'RADIO_WITH_DESCRIPTION', 'CHECKBOX', 'CHECKBOX_WITH_DESCRIPTION'].includes(formData.uiType)">
        <el-input type="textarea" v-model.trim="formData.options" rows=5 placeholder="一行一个选项"></el-input>
      </el-form-item>
      <el-form-item
          label="元数据"
          v-if="['LIST'].includes(formData.uiType)">
        <el-input type="textarea" v-model.trim="formData.items" rows=5 placeholder="JSON列表（高级功能，慎重使用）"></el-input>
      </el-form-item>

    </el-form>
    <div class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    dialogForCreation: {
      type: Boolean,
      default: false,
    },
    editingQuestion: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:dialogVisible'],
  setup(props, {emit}) {
    const formData = ref({
      enabled: true,
      displayName: '',
      fieldName: '',
      description: '',
      placeholder: '',
      uiType: '',
      valueType: '',
      options: '',
      items: '',
    });

    const createQuestion = () => {
      const data = {
        enabled: formData.value.enabled,
        display_name: formData.value.displayName,
        field_name: formData.value.fieldName,
        description: formData.value.description,
        placeholder: formData.value.placeholder,
        ui_type: formData.value.uiType,
        value_type: formData.value.valueType,
        meta: {},
      };
      if (formData.value.options) {
        data.meta.options = formData.value.options.split('\n')
        .map(option => option.trim())
        .filter(x => x && x.length > 0);
      }
      if (formData.value.items && formData.value.items.length > 0) {
        data.meta.items = JSON.parse(formData.value.items);
      }
      textmuse_api.createQuestion(data).then(() => {
        emit('update:dialogVisible', false);
        ElNotification({
          message: '创建成功',
          type: 'success',
        });
      }).catch((error) => {
        ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
          confirmButtonText: '确定',
          type: 'error',
        });
      });
    };

    const updateQuestion = () => {
      const data = {
        enabled: formData.value.enabled,
        display_name: formData.value.displayName,
        field_name: formData.value.fieldName,
        description: formData.value.description,
        placeholder: formData.value.placeholder,
        ui_type: formData.value.uiType,
        value_type: formData.value.valueType,
        meta: {
          options: formData.value.options.split('\n').map(option => option.trim()),
        },
      };
      if (formData.value.items) {
        data.meta.items = JSON.parse(formData.value.items)
      }
      textmuse_api.updateQuestion(props.editingQuestion.id, data).then(() => {
        emit('update:dialogVisible', false);
        ElNotification({
          message: '更新成功',
          type: 'success',
        });
      }).catch((error) => {
        ElMessageBox.alert(prettifyResponseError(error), '更新失败', {
          confirmButtonText: '确定',
          type: 'error',
        });
      });
    };

    const submitForm = () => {
      if (props.dialogForCreation) {
        createQuestion();
      } else {
        updateQuestion();
      }
    };

    const closeDialog = () => {
      emit('update:dialogVisible', false);
    };

    watch(() => props.dialogVisible, () => {
      if (!props.dialogForCreation && props.editingQuestion) {
        formData.value.enabled = props.editingQuestion.enabled;
        formData.value.displayName = props.editingQuestion.display_name;
        formData.value.fieldName = props.editingQuestion.field_name;
        formData.value.description = props.editingQuestion.description;
        formData.value.placeholder = props.editingQuestion.placeholder;
        formData.value.uiType = props.editingQuestion.ui_type;
        formData.value.valueType = props.editingQuestion.value_type;
        if (props.editingQuestion.meta.options)
          formData.value.options = props.editingQuestion.meta.options.join('\n').trim();
        if (props.editingQuestion.meta.items)
          formData.value.items = JSON.stringify(props.editingQuestion.meta.items, null, 2);
      } else {
        formData.value = Object();
      }
    });

    return {
      formData,
      closeDialog,
      submitForm,
    };
  },
};

</script>

<style lang="scss">
.question-editing-dialog {
  .dialog-footer {
    text-align: right;
  }

  label {
    min-width: 4.5rem;
    text-align: right;
  }
}
</style>
